import "./styles.less";

import Measure from "react-measure";
import { useImage } from "./Image.hook";
import { useEffect, useState } from "react";


const ImagenOptimizada = props => {
	const [dimensions, setdDimensions] = useState({
		width: 1,
		height: 1,
	});

	const [errored, setErrored] = useState(false);

	const { getImagenOptimizada } = useImage();

	useEffect(()=>{
		if(props.src){
			setErrored(false);
		}
		else{
			setErrored(true)
		}
	},[props.src])

	return (
		<Measure
			bounds
			onResize={contentRect => {
				//only first time rendering
				if (dimensions.width <= 1) {
					setdDimensions(contentRect.bounds);
				}
			}}>
			{({ measureRef }) => {
				const white_pixel =
					"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8zwMAAhABDcCVldsAAAAASUVORK5CYII=";

				const width = Math.round(Math.max(props.minWidth ? props.minWidth : 1, dimensions.width))

				const height = Math.round(Math.max(props.minHeight ? props.minHeight : 1, dimensions.height))

				const src = getImagenOptimizada({
					src: props.src,
					width: width,
					height: height,
					resize: props.resize,
				});
				

				const imgProps = {
					src: (width>1 || height>1) ? src : white_pixel,
					alt: width > 1 ? props.alt : "",
					width: width>1? width : null,
					height: height>1? height : null,
				};				

				for (const i in props) {
					if (["className", "width", "height", "style"].includes(i)) {
						imgProps[i] = props[i];
					}
				}

				if (errored) {
					imgProps.src =
						"https://cdn1.infocasas.com.uy/web/66575677eea43_screenshot-2024-05-29-at-11.20.20.png";
				}

				return (
					<>
						{props.backDrop && (
							<div
								className="back-drop"
								style={{ backgroundImage: `url(${imgProps.src})` }}
							/>
						)}
						<img
							ref={measureRef}
							{...imgProps}
							/* onError={() =>{!errored && setErrored({ ...dimensions, ...errored, errored: true })}}*/
						/>
						<style jsx>{`
							img {
								z-index: ${props.backDrop ? 2 : 0};
							}
						`}</style>
					</>
				);
			}}
		</Measure>
	);
};

export { ImagenOptimizada };
