import { AuthModalProvider } from "../shared-components/Components/User/AuthModal/web";
import React, { useEffect } from "react";
//import { OneTapSignIn } from "../shared-components/Components/User/OneTapSignIn/web";
import { useUser } from "../shared-components/Components/User/User.hook";
import Cookies from "universal-cookie";

export function withAuth(PageComponent) {
	const withAuth = ({ ...pageProps }) => {
		return (
			<>
				<AuthModalProvider />
				<UserInitialFetch />
				{/* @todo - uncomment this and fix bugs
				<OneTapSignIn />
				*/}
				<PageComponent {...pageProps} />
			</>
		);
	};

	if (PageComponent.getInitialProps) {
		withAuth.getInitialProps = async ctx => {
			let pageProps = {};
			if (PageComponent.getInitialProps) pageProps = await PageComponent.getInitialProps(ctx);
			return { ...pageProps };
		};
	}

	return withAuth;
}


export const UserInitialFetch = () => {
	const {user:{refetch}} = useUser();
	useEffect(() => {
		let isBrowser = typeof window !== "undefined"
		let token = isBrowser ? new Cookies(document.cookie).get("frontend_token") : null;
		if(token) refetch()
	},[])
	return <></>
}