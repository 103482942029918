import "./styles.less";

import React, { useContext, useEffect, useState } from "react";
import { useIsAuthModalShowing } from "../../../GlobalHooks/useIsAuthModalShowing";
import { useUser } from "../User.hook";
import { EVENT_NAME, ModalType } from "./openAuthModal";
import getConfig from 'next/config'

import dynamic from "next/dynamic"
import Cookies from "js-cookie";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
//@ts-ignore
const AuthModal = dynamic(() => import("./AuthModal").then(mod => mod.AuthModal), { ssr: false });



export const AuthModalProvider = () => {
	const [modalType, setModalType] = useState<ModalType>(false);
	const [fromCta, setFromCta] = useState<ModalType>(false);
	const [email, setEmail] = useState("");
	const { setIsAuthModalShowing } = useIsAuthModalShowing();
	const { country_code } = useContext(ConfigStateContext)
	const { loginBasePath, ovPath } = getConfig().publicRuntimeConfig;
	const { user } = useUser();

	const [event, setEvent] = useState({
		accept: data => { },
		reject: () => { },
	});

	const listener = ({ detail: { accept, reject, defaultModalType, email, isFromCta } }) => {
		console.warn(`${EVENT_NAME} FIRED`);
		setIsAuthModalShowing(true);
		if (!modalType) {
			setModalType(defaultModalType);
			setEvent({ accept: accept, reject: reject });
			setEmail(email);
			setFromCta(isFromCta);
		}
	};

	useEffect(() => {
		console.warn(`LISTENING TO ${EVENT_NAME} `);
		// @ts-ignore
		window.addEventListener(EVENT_NAME, listener);
		window[EVENT_NAME] = true;

		return () => {
			console.warn(`REMOVING LISTENER OF ${EVENT_NAME}`);
			// @ts-ignore
			window.removeEventListener(EVENT_NAME, listener);
			window[EVENT_NAME] = false;
		};
	}, []);

	const onCancel = () => {
		event.reject();
		setIsAuthModalShowing(false);
		setModalType(false);
	};

	const onOk = data => {
		event.accept(data);
		setIsAuthModalShowing(false);
		setModalType(false);
		user.refetch();
	};

	if (!modalType) {
		return null;
	}

	return <>{
		!!modalType && <AuthModal modalType={modalType} onCancel={onCancel} onOk={onOk} email={email} fromCta={fromCta} />
	}</>
};